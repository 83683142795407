<template>
  <img src="img/emblem.svg" width="100" height="100"/>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style scoped>
  img {
    opacity: 0;
    animation: fade 2s infinite;
  }

  @keyframes fade {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
</style>
